@import '../../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  /* animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative; */
}

.root {
  /* Gradient direction and overlaying the black color on top of the image for better readability */

  /* background: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)),
    url('https://tutulist-product-photos.s3.us-east-2.amazonaws.com/tutulist-sharetribe/SectionHero3.jpg');
  background-color: var(--matterColor);
  background-size: cover;
  background-position: center center; */

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (max-width: 767px) {
    background: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)),
      url('../../../assets/mobileBanner.jpg');
  }

  @media (--viewportLarge) {
    border-radius: 40px;
    border: solid 36px var(--matterColorBright);
  }
}

.heroContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media (--viewportMedium) {
    flex-direction: row;
    height: calc(100vh - 80px);
  }

  @media (max-width: 767px) {
    text-align: center;
    justify-content: center;
  }

  @media (min-width: 1500px) {
    margin: 0 auto;
    width: 100%;
  }
}

.heroLeftContent {
  width: 100%;

  @media (max-width: 767px) {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: rgb(255 255 255 / 84%);
    height: 100%;
    display: flex;
    align-items: center;
  }

  @media (--viewportMedium) {
    width: 50%;
  }

  & .heroInnerContent {
    width: 100%;
    margin-left: auto;
    padding: 24px 30px 24px 24px;

    @media (--viewportMedium) {
      max-width: calc(1200px / 2);
    }

    @media (--viewportLarge) {
      padding: 24px 50px 24px 24px;
    }

    @media (--viewportLargeWithPaddings) {
      padding: 24px 100px 24px 24px;
    }

    @media (--viewportMLarge) {
      padding: 24px 100px 24px 16px;
    }
  }
}

.heroRightContent {
  width: 100%;
  height: 100%;
  background: #f2f4f7;
  overflow: hidden;
  z-index: 0;
  position: relative;

  @media (--viewportMedium) {
    width: 50%;
  }

  & > img {
    max-width: 100%;
  }
  & :global(.slick-slide > div) {
    padding-bottom: 100%;
  }
  & :global(.slick-slide > div > img) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  & :global(.react-multi-carousel-dot-list) {
    bottom: 10px;
  }

  & :global(.react-multi-carousel-dot button) {
    border-color: var(--matterColorLight);
    background-color: var(--matterColorLight);
    border-width: 1px;
    opacity: 0.5;
  }

  & :global(.react-multi-carousel-dot.react-multi-carousel-dot--active button) {
    background-color: var(--marketplaceColor);
    border-color: var(--marketplaceColor);
    opacity: 1;
  }

  & :global(.slick-list) {
    @media (min-width: 320px) and (max-width: 425px) {
      height: 400px !important;
    }
  }
  & :global(.slick-list div) {
    @media (min-width: 320px) and (max-width: 425px) {
      height: 100%;
      position: relative;
    }
  }
  & :global(.slick-list div > img) {
    @media (min-width: 320px) and (max-width: 425px) {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
    }
  }
  & :global(.react-multi-carousel-item > img) {
    @media (min-width: 320px) and (max-width: 425px) {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.heroMainTitle {
  composes: marketplaceHeroTitleFontStyles from global;
  color: #101828;
  font-weight: var(--fontWeightSemiBold);
  font-size: 26px;
  composes: animation;
  animation-delay: 0.5s;
  line-height: 130%;
  font-size: 26px;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    font-size: 30px;
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }

  @media (--viewportLargeWithPaddings) {
    font-size: 36px;
  }

  @media (--viewportMLarge) {
    font-size: 46px;
  }
}

.heroSubTitle {
  composes: marketplaceH4FontStyles from global;

  color: #475467;
  margin: 0 0 24px 0;
  font-size: 15px;
  line-height: 150%;

  composes: animation;
  animation-delay: 0.65s;

  @media (--viewportSmall) {
    margin: 0 0 32px 0;
    font-size: 16px;
  }

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}

.heroButton {
  composes: marketplaceButtonStyles from global;
  color: #ffffff;
  composes: animation;
  line-height: 100%;
  animation-delay: 0.8s;

  @media (--viewportSmall) {
    max-width: 190px;
    line-height: 32px;
    width: 100%;
  }
}

.joinUsbox {
  background: #fff5fe;
  mix-blend-mode: multiply;
  border-radius: 16px;
  padding: 4px 10px 4px 10px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 16px;

  /* @media (max-width: 414px) {
    flex-direction: column;
  } */

  & > span {
    mix-blend-mode: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--marketplaceColor);
    display: inline-block;

    @media (max-width: 768px) {
      font-size: 11px;
    }
  }

  & > a {
    color: var(--marketplaceColor);
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-decoration: none;
    transition: all 0.5s ease 0s;
    background-color: var(--matterColorLight);
    padding: 2px 10px;
    border-radius: 16px;
    margin-left: 10px;

    @media (max-width: 768px) {
      font-size: 11px;
    }

    &:hover {
      text-decoration: none;
      transition: all ease 0.5s;
      color: #000;
      box-shadow: var(--boxShadow);
    }

    & > svg {
      margin-left: 7px;
      width: 20px;
    }
  }
}
