@import '../../styles/customMediaQueries.css';

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: var(--matterColorLight);
}

.heroRoot {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.hero {
  flex-grow: 1;
  justify-content: center;
}

.commonSection {
  padding: 40px 0px;
  background: white;

  &.greyBg {
    background-color: #fafafa;
  }

  @media (--viewportMedium) {
    padding: 70px 24px;
  }

  &.topCategorySection,
  &.popularDesignersSection {
  }

  & .fixWidthContainer {
    max-width: calc(1200px + 16px + 16px);
    margin: 0 auto;
    padding: 0px 16px;

    @media (--viewportMedium) {
      max-width: calc(1200px + 24px + 24px);
      padding: 0px 24px;
    }
  }
}

.findMoreServices {
  padding: 50px 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  background-attachment: fixed;

  @media (--viewportMedium) {
    padding: 140px 24px;
  }

  & h2 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 125%;
    color: #fff;
    margin-bottom: 10px;

    @media (--viewportMedium) {
      font-size: 36px;
    }
  }

  & p {
    font-size: 18px;
    line-height: 155%;
    color: #fff;
    font-weight: 400;
    margin: 0 0 30px 0;

    @media (--viewportMedium) {
      margin: 0 0 40px 0;
      font-size: 24px;
      line-height: 155%;
    }
  }

  & .seeAll {
    background-color: var(--matterColorLight);
    border: solid 1px var(--matterColorLight);
    color: var(--matterColorDark);
    font-size: 18px;
    font-weight: 500;
    line-height: 100%;
    border-radius: 0px;
    padding: 15px 30px;
    display: inline-block;
    text-decoration: none;
    transition: all ease 0.5s;

    @media (--viewportMedium) {
      padding: 20px 60px;
      font-size: 20px;
    }

    &:hover {
      text-decoration: none;
      transition: all ease 0.5s;
      background-color: transparent;
      border: solid 1px var(--matterColorLight);
      color: var(--matterColorLight);
    }
  }
}

.topSeller {
  & .sellerNav {
    & .nabTabs {
      @media (max-width: 991px) {
        overflow-x: scroll;
        padding-bottom: 15px;
      }

      & .tab {
        background-color: #fff;
        border-radius: 100px;
        padding: 10px 16px;
        border: solid 1px #ddd;
        color: #484848;
        margin-right: 10px;
        text-decoration: none;
        margin-left: 0;
        @media (--viewportMedium) {
          padding: 10px 24px;
        }

        &:hover,
        &.active {
          background-color: var(--marketplaceColor);
          color: #fff;
          border-color: var(--marketplaceColor);
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.commonSection {
  & .secHeader {
    margin: 0 0 23px 0;

    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 25px;
    }

    & h2 {
      font-size: 16px;
      font-weight: 600;
      color: var(--matterColorDark);
      margin: 0;

      @media (--viewportSmall) {
        font-size: 20px;
      }
      @media (--viewportMedium) {
        font-size: 25px;
      }
      & > img {
        width: 30px;
        margin-left: 5px;
        @media (--viewportMedium) {
          width: 40px;
        }
      }
    }

    & p {
      margin: 10px 0 0 0;
      font-size: 16px;
      color: var(--matterColorDark);
    }
  }
}

.howWorksWrapper {
  display: grid;
  margin-top: 100px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 70px;

  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }

  & .workCard {
    text-align: center;

    & > img {
      margin-bottom: 44px;
    }

    & h3 {
      font-size: 20px;
      color: var(--matterColor);
      margin: 0 0 12px 0;
    }

    & p {
      font-size: 16px;
      color: #484848;
      margin: 0;
    }
  }
}

.trendingSec {
  & .trendingCardWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;

    @media (max-width: 991px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
      display: flex;
      overflow-x: scroll;
    }

    @media (max-width: 480px) {
      grid-template-columns: repeat(1, 1fr);
    }

    & .trendingCard {
      @media (max-width: 767px) {
        min-width: calc(100% / 2 - 5%);
      }

      @media (max-width: 480px) {
        min-width: calc(100% / 1 - 20%);
      }

      &:first-child {
        & > a {
          height: 100%;

          & .cardImg {
            height: 100%;
          }
        }
      }

      & > a {
        display: inline-flex;
        width: 100%;
        transition: all ease 0.5s;
        height: 100%;
        justify-content: space-between;
        flex-direction: column;

        &:hover {
          transition: all ease 0.5s;
          text-decoration: none;

          & .cardImg {
            & > img {
              transform: scale(1.2);
            }
          }

          & > p {
            color: var(--marketplaceColor);
          }
        }

        & .cardImg {
          overflow: hidden;
          height: 100%;
          transition: all ease 0.5s;
          border-radius: 10px;

          & > img {
            transition: all ease 0.5s;
            max-width: 100%;
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
        }

        & > p {
          font-size: 16px;
          font-weight: 600;
          color: #484848;
          text-align: center;
        }
      }
    }
  }
}

.importantNote {
  background-color: #fff5fe;
  color: #cb5a97;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  padding: 6px 20px;
  line-height: 140%;
  & .closeBanner {
    & > svg {
      & > path {
        fill: var(--marketplaceColor);
      }
    }
    &:hover {
      & > svg {
        & > path {
          fill: var(--matterColorDark);
        }
      }
    }
  }
}

.buySellSection {
  text-align: center;
  padding: 40px 0px 40px 0px;
  background-color: #fff;
  /* border-bottom: solid 1px #eee8e7; */

  @media (--viewportMedium) {
    padding: 50px 0px 50px 0px;
  }

  & .fixWidthContainer {
    max-width: calc(1200px + 16px + 16px);
    margin: 0 auto;
    padding: 0px 16px;

    @media (--viewportMedium) {
      padding: 0px 24px;
      max-width: calc(1200px + 24px + 24px);
    }
  }

  & h2 {
    font-size: 20px;
    font-weight: 600;
    color: #101828;
    margin-top: 0;
    margin-bottom: 10px;
    line-height: 125%;

    @media (--viewportSmall) {
      font-size: 30px;
    }

    @media (--viewportMedium) {
      font-size: 40px;
    }
  }

  & p {
    font-size: 16px;
    margin: 0;
    color: #475467;
  }
}

.trendingCardWrapper {
  display: grid;
}

@media screen and (min-width: 750px) {
  .trendingCardWrapper {
    grid-auto-flow: column;
    grid-column-gap: var(--grid-desktop-horizontal-spacing);
    grid-row-gap: var(--grid-desktop-vertical-spacing);
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .trendingCard:nth-child(3n - 2) {
    grid-column: 1 / span 3;
    grid-row: span 2;
  }

  .trendingCard:nth-child(3n - 2):last-child {
    grid-column: 1 / span 3;
  }

  .trendingCard:nth-child(3n - 1),
  .trendingCard:nth-child(3n) {
    grid-column-start: 3;
    grid-column: 4 / span 10;
  }

  .trendingCard:nth-child(3n - 1):last-child {
    grid-row: span 2;
  }
}

/* bottom fixed mobile navbar*/
.exploreSellerSec {
  display: flex;
  flex-direction: column;

  & .topSellers {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;

    @media (--viewportMedium) {
      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch;
      overflow: initial;
    }
  }
}

.spinner {
  display: flex;
  justify-content: center;
}

.categorySliderSec {
  & .cards {
    display: inline-block;
    width: 100%;

    &:hover {
      text-decoration: none;
      transition: all ease 0.5s;

      & .cardInfo {
        color: var(--marketplaceColor);
      }

      & .cardImg {
        &::before {
          transition: all ease 0.5s;
          transform: scale(1);
        }
      }
    }

    & .cardImg {
      width: 100%;
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
      margin-bottom: 10px;
      padding-bottom: 100%;
      position: relative;
      transition: all ease 0.5s;

      &::before {
        width: 100%;
        height: 100%;
        content: '';
        background-color: rgb(0 0 0 / 12%);
        top: 0;
        left: 0;
        position: absolute;
        z-index: 1;
        transform: scale(0);
        transition: all ease 0.5s;
      }

      & > img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    & .cardInfo {
      font-size: 20px;
      font-weight: bold;
      color: var(--matterColor);
      margin: 0;
      line-height: 130%;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: center;
    }
  }
}

.brandSec {
  & .brandingCardWrapper {
    & .brandSliderSec {
      /* width: 100%; */

      & .cards {
        display: inline-block;
        width: 100%;
        border: solid 1px #ddd;
        padding: 10px;
        border-radius: 8px;

        &:hover {
          text-decoration: none;
          transition: all ease 0.5s;

          & .cardInfo {
            color: var(--marketplaceColor);
          }
        }

        & .cardImg {
          width: 100%;
          background-size: cover;
          background-position: top center;
          background-repeat: no-repeat;
          position: relative;
          transition: all ease 0.5s;

          & > img {
            max-width: 100%;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        & .cardInfo {
          font-size: 20px;
          font-weight: bold;
          color: var(--matterColor);
          margin: 0;
          line-height: 130%;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-align: center;
        }
      }
    }
  }
}

.titleHighlight {
  color: var(--marketplaceColor);
}

.closeBanner {
  cursor: pointer;
  & > svg {
    width: 15px;
    position: relative;
    left: 4px;
    bottom: 1px;
  }
}

.updateInProgress {
  & > svg {
    width: 15px;
    position: relative;
    left: 4px;
    bottom: 1px;
  }
}

.verifyEmail {
  text-decoration: underline;
  cursor: pointer;
}
